import { ENVIRONMENT } from "../../environment";

export class MailSenderService {
    public async MailSender(name:string, email:string, phone: string, subject:string) {

        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            `${'MAILSENDER_AUTH_TOKEN'}`
        );

        var formdata = new FormData();
        formdata.append(
            "jsonData",
            `{\n    \"to\": [\"communications@ziroh.com\"],\n \"bcc\": [\"zamil.maharoof@ziroh.com\",\"kireeti@ziroh.com\"],\n   \"subject\": \"${subject}\",\n    \"body\": \"Name: ${name} <br> Email: ${email} <br> Phone: ${phone}\"\n  }`
        );
        formdata.append("mailerKey", "zirohnotification");

        try {
            const response = await fetch(ENVIRONMENT.mailsenderUrl,
                {
                    method: "POST",
                    headers: myHeaders,
                    body: formdata,
                    redirect: "follow",
                }
            );
            const json = await response.json();
            return response;

        } catch (error) {
            // console.error('Error fetching data:', error);
        }
    }
}