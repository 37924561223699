import * as React from 'react';
import styles from './navbar.module.scss';
import { ReactComponent as ZunuLogo } from '../../../assets/svg/zunu-logo.svg';
import { ReactComponent as ZunuLogoColored } from '../../../assets/svg/zunu-logo-colored.svg';
import { ReactComponent as Sun } from '../../../assets/svg/sun.svg';
import { ReactComponent as Moon } from '../../../assets/svg/moon.svg';
import { ReactComponent as Ham } from '../../../assets/svg/ham.svg';
import { ReactComponent as CloseIcon } from '../../../assets/svg/icon_close_black.svg';
import { ReactComponent as ZunuCameraIcon } from '../../../assets/svg/zunu_cam_icon.svg';
import { ReactComponent as ZunuMailIcon } from '../../../assets/svg/zunu_mail_icon.svg';
import { ReactComponent as ZunuMessageIcon } from '../../../assets/svg/zunu_msg_icon.svg';
import { ReactComponent as ZunuDriveIcon } from '../../../assets/svg/zunu_drive_icon.svg';
import { ReactComponent as ZunuPortableIcon } from '../../../assets/svg/zunu_portable_icon.svg';
import { ReactComponent as Arrow } from '../../../assets/svg/arrow-down.svg';
import { Link, Route, useLocation } from 'react-router-dom';
import Button from '../Button/Button.component';
import  useNavbarHook  from './navbar.hook';
import { DEVICE, PLATFORM, THEME } from '../../constants/constants';
import { useEffect, useState } from 'react';
import { FlagsDropdown } from '../FlagsDropdown/flagsDropdown';
import { useDeviceStore } from '../../../StateManager/DeviceStore';
import { OutsideClickHandler } from '../OutsideClickHandler/OutsideClickHandler';
import { ReactComponent as ZunuGatewayIcon } from '../../../assets/svg/messagingGatewayIcon.svg';
import { ReactComponent as ZunuDBLogo } from '../../../assets/svg/zunu_db_logo.svg';
import { ReactComponent as SecureNumericLogo } from '../../../assets/svg/secureNumericLogo.svg';
import { ReactComponent as SecureStringLogo } from '../../../assets/svg/secure_string_logo.svg';
import { ReactComponent as SecureDataStructuresLogo } from '../../../assets/svg/secure_data_structures_logo.svg';
import useAppHook from '../../../App.hook';
import useLayout from '../../../Layout/layout.hook';
import { useDownloadBtnStore } from '../../../StateManager/DownloadBtnStore';
import ReactDOM from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ROUTES } from '../../constants/routes';
import { ReactComponent as ZunuForBusinessLogo } from '../../../assets/svg/For_business_logo.svg';

const variants = {
	open: {
		x: '0vw',
		transition: {
			x: { stiffness: 200 }
		}
	},

	closed: {
		x: '100vw'
	},

	exit: {
		x: '80vw'
	}
};


export default function Navbar() {
	const {
		theme,
		setTheme,
		os,
		setOs,
		handleThemetoggle,
		handleDownloadClick,
		isSideMenu,
		setIsSideMenu,
		isProductsDropdown,
		setIsProductsdDropdown,
		handleHamClick,
		closeSideMenu,
		isBusinessDropdown,
		setIsBusinessDropdown,
		isTransition,
		setIsTransition,
		handleBusinessDropdownClose,
		handleBusinessClick,
		handleProductsDropdownClose,
		handleProductsClick,
	} = useNavbarHook();

	const { isFooter, setIsFooter } = useLayout();
	const { device, setDevice } = useDeviceStore();
	const { isDownloadBtn, setIsDownloadBtn } = useDownloadBtnStore();

	const location = useLocation();

	const sideMenuActiveStyle = {
		// width: `80vw`,
	};

	return (
		<nav className={`${styles.navbar}`}>
			{!isFooter ? (
				<div className={styles.logoContainer}>
					{theme == THEME.DARK ? (
						<ZunuLogo className={styles.logo} />
					) : (
						<ZunuLogoColored className={styles.logo} />
					)}
				</div>
			) : (
				<div className={styles.logoContainer}>
					<Link
						reloadDocument
						aria-label="Read more about Zunu"
						to={(ROUTES.HOME)}
					>
						{theme == THEME.DARK ? (
							<ZunuLogo className={styles.logo} />
						) : (
							<>
								<ZunuLogoColored className={styles.logo} />
							</>
						)}
					</Link>
				</div>
			)}

			{isFooter && (
				<div className={styles.navOptions}>
					{device == DEVICE.DESKTOP && (
						<div className={styles.navLinks}>
							<OutsideClickHandler
								onOutsideClick={() => {
									handleProductsDropdownClose();
								}}
							>
								<div className={styles.navOption}>
									<p className={`${isProductsDropdown ? `${styles.navOptionActive}` : ''}`}
										onClick={() => { handleProductsClick(); }}>
										Products <Arrow />
									</p>
									<div className={`${styles.navDropdown} ${isProductsDropdown ? `${styles.navDropdownActive}` : ''}`}>
										<Link
											reloadDocument
											aria-label="Zunu Camera"
											to={ROUTES.PRODUCTS.CAMERA}
											id="navCamera"
										>
											<div className={styles.menuProduct}>
												<div className={styles.productLogo} >
													<ZunuCameraIcon />
													<p>Camera</p>
												</div>
												<p className={styles.productDescription}>
													A Privacy-Preserving Camera
													app that captures and stores
													photos and videos with the
													utmost confidentiality.
												</p>
											</div>
										</Link>
										<Link
											reloadDocument
											aria-label="Zunu Mail"
											to={ROUTES.PRODUCTS.MAIL}
											id="navMail"
										>
											<div className={styles.menuProduct}>
												<div className={styles.productLogo}>
													<ZunuMailIcon />
													<p>Mail</p>
												</div>
												<p className={styles.productDescription}>
													A Privacy-Preserving Email
													System that makes emails
													invisible to email service
													providers and other third
													parties.
												</p>
											</div>
										</Link>
										<Link
											reloadDocument
											aria-label="Zunu Message"
											to={ROUTES.PRODUCTS.MESSAGE}
											id="navMessage"
										>
											<div className={styles.menuProduct}>
												<div className={styles.productLogo}>
													<ZunuMessageIcon />
													<p>Message</p>
												</div>
												<p className={styles.productDescription}>
													A Privacy-Preserving
													Messaging app to send and
													receive messages with 100%
													Privacy.
												</p>
											</div>
										</Link>
										<Link
											reloadDocument
											aria-label="Zunu Drive"
											to={ROUTES.PRODUCTS.DRIVE}
											id="navDrive"
										>
											<div className={styles.menuProduct}>
												<div className={styles.productLogo}>
													<ZunuDriveIcon />
													<p>Drive</p>
												</div>
												<p className={styles.productDescription}>
													A Privacy-Preserving File
													System that keeps files
													private on Devices and the
													Cloud.
												</p>
											</div>
										</Link>
										<Link
											reloadDocument
											aria-label="Zunu Portable"
											to={ROUTES.PRODUCTS.PORTABLE}
											id="navPortable"
										>
											<div className={styles.menuProduct}>
												<div className={styles.productLogo}>
													<ZunuPortableIcon />
													<p>Portable</p>
												</div>
												<p className={styles.productDescription}>
													Privacy-Preserving enclave
													to protect files on USB
													devices.
												</p>
											</div>
										</Link>
									</div>
								</div>
							</OutsideClickHandler>



							{/* <Link to={ROUTES.SOLUTIONS.ZUNU_BUSINESS} reloadDocument className={styles.navOption}>For Business</Link> */}


							{/* <Link
								reloadDocument
								to={ROUTES.PRICING}
								className={styles.navOption}
							>
								Pricing
							</Link> */}

							<Link
								to={ROUTES.SUPPORT}
								reloadDocument
								className={styles.navOption}
							>
								Help & Support
							</Link>
						</div>
					)}

					<div className={styles.flags_and_button_container}>
						{/* <div
    onClick={() => {
      handleThemetoggle();
    }}
    className={styles.toggleContainer}
  >
    {theme == THEME.LIGHT ? (
      <Moon className={styles.toggleIcon} />
    ) : (
      <Sun className={styles.toggleIcon} />
    )}
  </div> */}

						{/* {!Object.values(ROUTES.SOLUTIONS).includes(location.pathname) && (
							<div className={styles.flags}>
								<FlagsDropdown />
							</div>
						)} */}

						<div className={styles.downloadButtonContainer}
							onClick={handleDownloadClick}>
							<Button text="Download Beta" id="navDownload" />
						</div>

						{/* {Object.values(ROUTES.SOLUTIONS).includes(location.pathname) && (
							<Link
								reloadDocument
								to={`${ROUTES.CONTACT_US}`}
								className={styles.downloadButtonContainer}
							>
								<Button type="secondary" text="Contact us" />
							</Link>
						)} */}

						{device !== DEVICE.DESKTOP && (
							<Ham
								className={styles.ham}
								onClick={handleHamClick}
							/>
						)}
					</div>
				</div>
			)}
			<AnimatePresence>
				{isSideMenu && (
					<motion.div className={styles.sideMenuContainer}>
						<div className={styles.sideMenuWrapper}>
							<OutsideClickHandler
								onOutsideClick={() => {
									closeSideMenu();
								}}
							>
								<motion.div
									variants={variants}
									initial={'closed'}
									animate={isSideMenu ? 'open' : 'closed'}
									exit="exit"
									// style={sideMenuActiveStyle}
									// style={{ touchAction: 'none' }}
									className={isSideMenu ? `${styles.sideMenu} ${styles.sideMenuActive}` : `${styles.sideMenu}`}
								>
									<CloseIcon
										className={styles.menuCloseIcon}
										onClick={closeSideMenu}
									/>
									<div className={styles.menu}>
										<p className={styles.menuHeading}>
											Explore More Products
										</p>

										<div className={styles.menuProductsContainer}>
											{!location.pathname.startsWith(ROUTES.PRODUCTS.CAMERA) && (
												<Link
													reloadDocument
													aria-label="Zunu Camera"
													to={ROUTES.PRODUCTS.CAMERA}
													id="mobileNavCamera"
												>
													<div className={styles.menuProduct}>
														<div className={styles.productLogo}>
															<ZunuCameraIcon />
															<p>Camera</p>
														</div>
														<p className={styles.productDescription}>
															A Privacy-Preserving
															Camera app that
															captures and stores
															photos and videos
															with the utmost
															confidentiality.
														</p>
													</div>
												</Link>
											)}

											{!location.pathname.startsWith(ROUTES.PRODUCTS.MAIL) && (
												<Link
													reloadDocument
													aria-label="Zunu Mail"
													to={ROUTES.PRODUCTS.MAIL}
													id="mobileNavMail"
												>
													<div className={styles.menuProduct}>
														<div className={styles.productLogo}>
															<ZunuMailIcon />
															<p>Mail</p>
														</div>
														<p className={styles.productDescription}>
															A Privacy-Preserving
															Email System that
															makes emails
															invisible to email
															service providers
															and other third
															parties.
														</p>
													</div>
												</Link>
											)}

											{!location.pathname.startsWith(ROUTES.PRODUCTS.MESSAGE) && (
												<Link
													reloadDocument
													aria-label="Zunu Message"
													to={ROUTES.PRODUCTS.MESSAGE}
													id="mobileNavMessage"
												>
													<div className={styles.menuProduct}>
														<div className={styles.productLogo}>
															<ZunuMessageIcon />
															<p>Message</p>
														</div>
														<p className={styles.productDescription}>
															A Privacy-Preserving
															Messaging app to
															send and receive
															messages with 100%
															Privacy.
														</p>
													</div>
												</Link>
											)}

											{!location.pathname.startsWith(ROUTES.PRODUCTS.DRIVE) && (
												<Link
													reloadDocument
													aria-label="Zunu Drive"
													to={ROUTES.PRODUCTS.DRIVE}
													id="mobileNavDrive"
												>
													<div className={styles.menuProduct}>
														<div className={styles.productLogo}>
															<ZunuDriveIcon />
															<p>Drive</p>
														</div>
														<p className={styles.productDescription}>
															A Privacy-Preserving
															File System that
															keeps files private
															on Devices and the
															Cloud.
														</p>
													</div>
												</Link>
											)}

											{!location.pathname.startsWith(ROUTES.PRODUCTS.PORTABLE) && (
												<Link
													reloadDocument
													aria-label="Zunu Portable"
													to={ROUTES.PRODUCTS.PORTABLE}
													id="mobileNavPortable"
												>
													<div className={styles.menuProduct}>
														<div className={styles.productLogo}>
															<ZunuPortableIcon />
															<p>Portable</p>
														</div>
														<p className={styles.productDescription}>
															Privacy-Preserving
															enclave to protect
															files on USB
															devices.
														</p>
													</div>
												</Link>
											)
											}

										</div>

										<hr />

										{/* <Link
											className={styles.menuHeading}
											reloadDocument
											to={ROUTES.SOLUTIONS.ZUNU_BUSINESS}
										>
											For Business
										</Link>

										<hr /> */}

										{/* <Link
											className={styles.menuHeading}
											reloadDocument
											to={ROUTES.PRICING}
										>
											Pricing
										</Link> */}

										<Link
											className={styles.menuHeading}
											reloadDocument
											to={ROUTES.SUPPORT}
										>
											Help & Support
										</Link>
										<hr />
										{/* <Link
											className={styles.menuHeading}
											reloadDocument
											to={ROUTES.DOWNLOAD}
										>
											Downloads
										</Link>
										<hr /> */}
									</div>
								</motion.div>
							</OutsideClickHandler>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</nav >
	);
}
