import React, { useEffect } from 'react'
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import {ReactComponent as CircleArrow} from '../../../../assets/svg/arrowCircle.svg';
import styles from './productFaqs.module.scss';


//LAZY LOADING
import { lazily } from 'react-lazily';
const { FAQArticle } = lazily(() => import('../FAQArticle/faqArticle.component'));

function ProductFaqs(questions:any) {
  
  return (
    <div className={`${styles.faqContainer}`} style={{backgroundColor: `${questions.background}`, backgroundImage: `${questions.backgroundImg}`}}>
      <Fade>
        <p className={styles.faqHeading}>{questions.heading}</p>
      </Fade>
      <div className={styles.faqWrapper}>
        {questions.data.map((question: any) => {
          return <FAQArticle title={question.title} contents={question.contents} />
        })}
        <Link reloadDocument to={questions.url} className={styles.faqMore}>
          <p>Know more</p>
          <CircleArrow className={styles.arrow} />
        </Link>
      </div>
    </div>
  )
}

export  {ProductFaqs}