import React from 'react'
import { useDownloadHook } from './download.hook';
import styles from './downloads.module.scss';
import { ReactComponent as AppleIcon } from '../../assets/svg/apple_icon.svg';
import { ReactComponent as WindowsIcon } from '../../assets/svg/windows_icon.svg';
import { ReactComponent as LinuxIcon } from '../../assets/svg/linux_icon.svg';
import { ReactComponent as PortableIcon } from '../../assets/svg/zunu_portable_icon.svg';
import { ReactComponent as AndroidIcon } from '../../assets/svg/android_icon.svg';
import { ReactComponent as AppStoreLogo } from '../../assets/svg/appStore_logo.svg';
import { ReactComponent as PlayStoreLogo } from '../../assets/svg/playStore_logo.svg';
import { ReactComponent as IndusAppStoreLogo } from '../../assets/svg/IndusAppstore_logo.svg';
import { ReactComponent as DownloadIcon } from '../../assets/svg/download_icon.svg';
import { ReactComponent as PlaystoreQr } from '../../assets/svg/playstore_qr.svg';
import { ReactComponent as AppstoreQr } from '../../assets/svg/appstore_qr.svg';
import IndusAppStoreQR from '../../assets/png/indusappstore_qr.png';
import { DEVICE, PLATFORM } from '../../_shared/constants/constants';
import { Helmet } from 'react-helmet';
import { useDownloadBtnStore } from '../../StateManager/DownloadBtnStore';
import { useDeviceStore } from '../../StateManager/DeviceStore';


export default function Downloads() {

  const { isDownloadBtn, setIsDownloadBtn } = useDownloadBtnStore();
  const { device, setDevice } = useDeviceStore();
  const {
    handleBuildDownload,
  } = useDownloadHook();

  return (
    <div className={styles.downloadPage}>

      <Helmet>
        <meta name="description" content="Download Zunu" />
        <title>Download Zunu - Keep your private life private.</title>

        <meta property="og:site_name" content="Zunu" />
        <meta property="og:title" content="Zunu Camera - Keep your private life private." />
        <meta property="og:description" content="Download Zunu" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://zunuprivacy.com/icon192.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zirohlabs" />
        <meta name="twitter:title" content="Zunu: Software-defined security stack with privacy-preserving, performant cryptographic algorithms." />
        <meta name="twitter:description" content="Download Zunu" />
        <meta name="twitter:image"
          content="https://zunuprivacy.com/icon192.png" />
      </Helmet>
      <p className={styles.pageHeading}>Zunu Downloads</p>

      <div className={styles.downloadsWrapper}>
        <div className={`${styles.deviceDownloadContainer} ${styles.desktopDownloads}`}>
          <p className={styles.title}>Download Zunu Suite for Desktop</p>

          <div className={styles.osDownloadsWrapper}>

            <div className={styles.downloadContainer}>
              <AppleIcon className={styles.osIcon} />
              <div className={styles.contentWrapper}>
                <div className={styles.configsWrapper}>
                  <p className={styles.osName}>Mac OS</p>
                  <div className={styles.osConfigs}>
                    {/* <div className={styles.config}>
                      <p className={styles.configName}>Intel Processor</p>
                      {device === DEVICE.MOBILE &&
                        <DownloadIcon />
                      }
                    </div> */}
                    <div className={styles.config} onClick={() => { handleBuildDownload(PLATFORM.MAC) }}>
                      <p className={styles.configName}>Download</p>
                      {device === DEVICE.MOBILE &&
                        <DownloadIcon />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.downloadContainer}>
              <WindowsIcon className={styles.osIcon} />
              <div className={styles.contentWrapper}>
                <div className={styles.configsWrapper}>
                  <p className={styles.osName}>Windows</p>
                  <div className={styles.osConfigs}>
                    <div className={styles.config} onClick={() => { handleBuildDownload(PLATFORM.WIN) }}>
                      <p className={styles.configName}>Download</p>
                      {device === DEVICE.MOBILE &&
                        <DownloadIcon />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className={styles.downloadContainer}>
              <AppleIcon className={styles.osIcon} />
              <div className={styles.contentWrapper}>
                <div className={styles.configsWrapper}>
                  <p className={styles.osName}>Linux</p>
                  <div className={styles.osConfigs}>
                    <div className={styles.config}>
                      <p className={styles.configName}>Ubuntu 18+</p>
                      <DownloadIcon />
                    </div>
                    <div className={styles.config}>
                      <p className={styles.configName}>Ubuntu 22+</p>
                      <DownloadIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

          </div>
        </div>


        <div className={`${styles.deviceDownloadContainer} ${styles.mobileDownloads}`}>
          <p className={styles.title}>Download Zunu Apps for Mobile</p>

          <div className={styles.osDownloadsWrapper}>

            <div className={styles.downloadContainer}>
              <AppleIcon className={styles.osIcon} />
              <div className={styles.contentWrapper}>
                <div className={styles.configsWrapper}>
                  <p className={styles.osName}>iOS</p>
                  <div className={styles.osConfigs}>
                    <div className={styles.config}>
                      <AppStoreLogo className={styles.storeLogo} onClick={() => { handleBuildDownload(PLATFORM.IOS) }} />
                    </div>
                  </div>
                </div>
                {device !== DEVICE.MOBILE &&
                  <AppstoreQr className={styles.qrCode} />
                }
              </div>
            </div>

            <div className={styles.downloadContainer}>
              <AndroidIcon className={styles.osIcon} />
              <div className={styles.contentWrapper}>
                <div className={styles.configsWrapper}>
                  <p className={styles.osName}>Android</p>
                  <div className={styles.osConfigs}>
                    <div className={styles.config}>
                      <PlayStoreLogo className={styles.storeLogo} onClick={() => { handleBuildDownload(PLATFORM.AND) }} />
                    </div>
                  </div>
                </div>
                {device !== DEVICE.MOBILE &&
                  <PlaystoreQr className={styles.qrCode} />
                }
              </div>
            </div>

          </div>
        </div>

        <div className={`${styles.deviceDownloadContainer} ${styles.portableDownloads}`}>
          <p className={styles.title}>USB and Storage Devices</p>

          <div className={styles.osDownloadsWrapper}>

            <div className={styles.downloadContainer}>
              <PortableIcon className={styles.osIcon} />
              <div className={styles.contentWrapper}>
                <div className={styles.configsWrapper}>
                  <p className={styles.osName}>Zunu Portable</p>
                  <div className={styles.osConfigs}>
                    <div className={styles.config} onClick={() => { handleBuildDownload(PLATFORM.WIN_PORATBLE) }}>
                      <p className={styles.configName}>Windows</p>
                      {device === DEVICE.MOBILE &&
                        <DownloadIcon />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>
  )
}