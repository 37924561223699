import {
	createBrowserRouter,
	RouterProvider,
	createHashRouter
} from 'react-router-dom';
import useAppHook from './App.hook';
import Layout from './Layout/layout';
import Downloads from './Pages/Downloads/downloads';
import Faq from './Pages/Faq/faq';
import Home from './Pages/Home/Home';
import { Pricing } from './Pages/Pricing/Pricing';
import PrivacyPolicy from './Pages/PrivacyPolicy/privacyPolicy';
import Terms from './Pages/TermsOfService/terms';
import { ZunuCamera } from './Pages/ZunuCamera/zunuCamera';
import { ZunuDrive } from './Pages/ZunuDrive/zunuDrive';
import { ZunuMail } from './Pages/ZunuMail/zunuMail';
import { ZunuMessage } from './Pages/ZunuMessage/zunuMessage';
import ContactUs from './Pages/ContactUs/contactUs';
import ThingsZunuDoes from './Pages/ThingsZunuDoes/thingsZunuDoes';
import Support from './Pages/Support/support';
import { useEffect, useState } from 'react';
import NotFound from './Pages/NotFound/notfound';
import Hackathon from './Pages/Hackathon/hackathon';
import CryptoChallenge from './Pages/CryptoChallenge/cryptoChallenge';
import ZunuPortable from './Pages/ZunuPortable/zunuPortable';
import PrivacyPolicyMobile from './Pages/PrivacyPolicy/privacyPolicyMobile';
import TermsMobile from './Pages/TermsOfService/termsMobile';
import SupportMobile from './Pages/Support/supportMobile';
import { useDownloadBtnStore } from './StateManager/DownloadBtnStore';
import AcademicAlliance from './Pages/AcademicAlliance/academicAlliance';
import PartnerUniversities from './Pages/AcademicAlliance/PartnerUniversities/partnerUniversities';
import StoreRedirect from './_shared/components/StoreRedirect/storeRedirect.component';
import { ROUTES } from './_shared/constants/routes';

const Routing = (props: any) => {
	const AppRouter = window.process ? createHashRouter : createBrowserRouter;

	// const { isButtonVisible, setIsButtonVisible } = useAppHook();
	const { isDownloadBtn, setIsDownloadBtn } = useDownloadBtnStore();

	const routes = AppRouter([
		{
			path: `/`,
			element: <Layout> </Layout>,

			children: [
				{
					path: `${ROUTES.HOME}`,
					element: <Home />
				},
				// {
				// 	path: `pricing`,
				// 	element: <Pricing />
				// },
				{
					path: `${ROUTES.PRODUCTS.CAMERA}`,
					element: <ZunuCamera />
				},
				{
					path: `${ROUTES.PRODUCTS.MESSAGE}`,
					element: <ZunuMessage />
				},
				{
					path: `${ROUTES.PRODUCTS.MAIL}`,
					element: <ZunuMail />
				},
				{
					path: `${ROUTES.PRODUCTS.DRIVE}`,
					element: <ZunuDrive />
				},
				{
					path: `${ROUTES.DOWNLOAD}`,
					element: <Downloads />
				},
				{
					path: `${ROUTES.TERMS}`,
					element: <Terms />
				},
				{
					path: `${ROUTES.POLICY}`,
					element: <PrivacyPolicy />
				},
				{
					path: `${ROUTES.CONTACT_US}`,
					element: <ContactUs />
				},
				{
					path: `${ROUTES.THINGS_ZUNU_DOES}`,
					element: <ThingsZunuDoes />
				},
				{
					path: `${ROUTES.SUPPORT}`,
					element: <Support />
				},
				{
					path: `${ROUTES.CRYPTO_CHALLENGE}`,
					element: <CryptoChallenge />
				},
				{
					path: `${ROUTES.HACKATHON}`,
					element: <Hackathon />
				},
				{
					path: `${ROUTES.PRODUCTS.PORTABLE}`,
					element: <ZunuPortable />
				},
				// {
				// 	path: `${ROUTES.ACADEMIC_ALLIANCE}`,
				// 	children: [
				// 		{
				// 			path: `${ROUTES.ACADEMIC_ALLIANCE}`,
				// 			element: <AcademicAlliance />,
				// 		},
				// 		{
				// 			path: `${ROUTES.ACADEMIC_ALLIANCE_PARENTS}`,
				// 			element: <PartnerUniversities />
				// 		},
				// 	]
				// },
				{
					path:"/dl/:application/:code", element: <StoreRedirect /> 
				},
				{
					path: 'mobile',
					children: [
						{
							path: 'privacy-policy',
							element: <PrivacyPolicyMobile />
						},
						{
							path: 'terms-of-service',
							element: <TermsMobile />
						},
						{
							path: `support`,
							element: <SupportMobile />
						}
					]
				},
				{
					path: `/*`,
					element: <NotFound />
				}
			]
		}
	]);

	return <RouterProvider router={routes} />;
};

export default Routing;
